<template>
  <div>
    <page-title :heading="$t('settings.lang_navScaleSettings')" :subheading="$t('generic.lang_create') " :icon=icon :show-previous-button="true" ></page-title>
    <div class="app-main__inner">
      <CreateScaleSettingsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import CreateScaleSettingsComponent from "../../../components/settings/moduleSettings/CreateScaleSettingsComponent";

export default {
  components: {
    CreateScaleSettingsComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>
<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{$t('settings.lang_navScaleSettings')}}
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-form lazy-validation ref="scaleSettingsForm" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="host" :disabled="true" outlined  :rules="[rules.ipAddress]" id="ipv4" aria-autocomplete="false"
                          :label="$t('settings.lang_ipOfScaleApi')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC" v-model="port" type="number" :rules="[rules.required]" :disabled="true" outlined aria-autocomplete="false"
                          :label="$t('settings.lang_portOfScaleAPI')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="cashierID" :items="cashierIDS" :rules="[rules.required]" :disabled="loading" outlined  item-text="name" item-value="id"
                        :label="$t('generic.lang_cashierID')">

              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-alert
                  :value="!!msg"
                  color="primary"
                  dark
                  border="left"
                  text
                  transition="translate-x-transition"
                  dismissible
              >
                {{msg}}
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="manufacturer" :items="manufacturers" :disabled="loading" outlined  item-text="name" item-value="value"
                        :label="$t('erp.lang_nav_manufacturer')">

              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions :class="{'d-block':$vuetify.breakpoint.xs,'text-right':true}">

        <v-spacer/>
        <v-btn color="success" :disabled="loading || !valid" :loading="loading" @click="saveScaleSettings">{{
            $t('generic.lang_save')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import validation from "../../../mixins/validation/validation";
import mixin from '../../../mixins/KeyboardMixIns'

export default {
  name: "CreateScaleSettingsComponent",
  mixins:[validation,mixin],
  data() {
    return {
      loading: false,
      host: '127.0.0.1',//default 127.0.0.1
      port: 3086,//default 3086
      cashierID:null,
      manufacturer :null,
      valid:true,
      msg:'',
    }
  },
  computed:{
    ...mapState([
      "cashierIDs",
    ]),
    cashierIDS(){
      return this.cashierIDs.availableCashierIDs.map(cashier=> {return {id:cashier,name:'Kasse '+cashier}})
    },
    manufacturers(){
      return [
        {
          name:this.$t('generic.lang_notDefined'),
          value:null,
        },
        {
          name:'Mettler Toledo',
          value:'Mettler Toledo',
        },
        {
          name:'Ohaus',
          value:'Ohaus',
        },
      ];
    },
  },
  methods: {
    saveScaleSettings() {

      if(!this.$refs.scaleSettingsForm.validate()){
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.SCALE.CREATE, {
        ipAddress: this.host,
        port: this.port,
        cashierID:this.cashierID,
        manufacturer:this.manufacturer,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.$refs.scaleSettingsForm.reset();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    getScaleStatus() {
      if(!this.$refs.scaleSettingsForm.validate()){
        return;
      }
      this.loading = true;

      //CREATE NEW AXIOS INSTANCE
      let axios = this.axios.create();
      //REMOVE 3POS HEADERS
      delete axios.defaults.headers.common['3POSAPITOKEN'];
      delete axios.defaults.headers.common['3POSBS'];
      delete axios.defaults.headers.common['3POSBSID'];

      axios.get('http://'+this.host+':'+this.port+'/get-status', {

      }).then((res) => {
        if (res.data.status) {

          this.msg=res.data.msg;

          setTimeout(()=> {
            this.msg = ''
            clearTimeout();
          },5000)

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    showVersion() {
      if(!this.$refs.scaleSettingsForm.validate()){
        return;
      }
      this.loading = true;

      //CREATE NEW AXIOS INSTANCE
      let axios = this.axios.create();
      //REMOVE 3POS HEADERS
      delete axios.defaults.headers.common['3POSAPITOKEN'];
      delete axios.defaults.headers.common['3POSBS'];
      delete axios.defaults.headers.common['3POSBSID'];

      axios.get('http://'+this.host+':'+this.port+'/show-version', {

      }).then((res) => {
        if (res.data.status) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    hideVersion() {
      if(!this.$refs.scaleSettingsForm.validate()){
        return;
      }
      this.loading = true;

      //CREATE NEW AXIOS INSTANCE
      let axios = this.axios.create();
      //REMOVE 3POS HEADERS
      delete axios.defaults.headers.common['3POSAPITOKEN'];
      delete axios.defaults.headers.common['3POSBS'];
      delete axios.defaults.headers.common['3POSBSID'];

      axios.get('http://'+this.host+':'+this.port+'/hide-version', {

      }).then((res) => {
        if (res.data.status) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {

  }


}
</script>

<style scoped>

</style>